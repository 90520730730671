<template>
  <div>
    <b-row>
      <b-col md="12">

        <b-button
          variant="primary"
          class="mb-2"
          @click="submitSyncGoogle"
        >
          Resync
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
  BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BCol,
    BButton,

    // form validation
  },

  props: {
    currentUser: {
      type: Object,
      required: true,
    },
    currentDomains: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      userData: this.currentUser,

      // conversion rate form
      conversionRateForm: {
        salesCloseRate: null,
        averageSales: null,
      },
      selectedCurrency: null,
    }
  },
  computed: {
  },
  created() {
  },
  methods: {
    loadFormData() {
      // load data for forms
      this.selectedCurrency = this.userData.currency // load default currency

      if (this.currentDomains.length > 0) { // this.userData.domains.length > 0
        const firstDomain = this.currentDomains[0]
        if (firstDomain.conversion_values) {
          // set conversion values in form
          this.conversionRateForm.salesCloseRate = firstDomain.conversion_values.sales_won_percent
          this.conversionRateForm.averageSales = firstDomain.conversion_values.average_invoice_amount_pennies * 0.01
        }
      }
    },

    /**
     * this method is called when the user clicks the "Save" button on the conversion values step
     * it posts the conversion values (user entered) to the api
     */
    submitConversionValue() {
      // post the conversion values to the api
      const newConversionValues = {
        average_invoice_amount_pennies: Number(this.conversionRateForm.averageSales) * 100, // convert to pennies
        sales_won_percent: Number(this.conversionRateForm.salesCloseRate),
      }
      useJwt.setConversionValue(this.currentDomains[0].id, newConversionValues) // TODO: select correct domain
        .then(() => {
          // update userdata
          // this.refreshUserData()
          this.$emit('updateConversionValue', newConversionValues)

          // confirm success
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Conversion Value Set',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'You have successfully set your conversion value.',
            },
          })
        })
        .catch(error => {
          console.log('submitConversionValue ERROR', error.response.data)
          this.displayErrorMessage(error.response.data.error || error.response.data)
        })
    },

    submitSyncGoogle() {
      console.log('in submitSyncGoogle')
      useJwt.postSyncGoogleCustomers()
        .then(response => {
          const responseData = response.data.data
          console.log('woot ', responseData)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Google customers resynced',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'You have successfully resynced your Google customers cache.',
            },
          })
        })
        .catch(error => {
          console.log('woot ERROR', error.response)
        })
    },

    /**
     * update currency when form changed
     */
    currencyChanged(newValue) {
      // set new default currency
      if (newValue !== this.userData.currency) {
        useJwt.updateDefaultCurrency(newValue)
          .then(response => {
            const responseData = response.data.data
            console.log('updateDefaultCurrency', responseData)
            // this.refreshUserData()
            this.userData.currency = this.selectedCurrency
          })
          .catch(error => {
            console.log(error.response.data)
          })
      }
    },

    displayErrorMessage(errorMessage) {
      const formattedMessage = typeof errorMessage === 'object' && errorMessage !== null ? JSON.stringify(errorMessage) : errorMessage
      // display error message
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Error',
          icon: 'AlertCircleIcon',
          variant: 'danger',
          text: formattedMessage,
        },
      })
    },
  },
}
</script>

<style lang="scss">
</style>
